import React, { useState, useEffect } from "react";
// import './adminlte.css';
import "./scss/adminlte.scss";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
const AboutUs = React.lazy(() => import("./components/about-us/aboutus"));
const BlogTitle = React.lazy(() => import("./components/blog/blogTitle"));
const Blog = React.lazy(() => import("./components/blog/blog"));
const Blogs = React.lazy(() => import("./components/blog/blogs"));
const BankCart = React.lazy(() => import("./components/bank-cart/bankCart"));
const BankCarts = React.lazy(() => import("./components/bank-cart/bankCarts"));
const Categories = React.lazy(() => import("./components/category/categories"));
const Category = React.lazy(() => import("./components/category/category"));
const Certificate = React.lazy(() =>
  import("./components/certificate/certificate")
);
const CertificateList = React.lazy(() =>
  import("./components/certificate/certificateList")
);
const ContactTitle = React.lazy(() =>
  import("./components/contact/contactTitle")
);
const ContactForm = React.lazy(() =>
  import("./components/contact/contactForm")
);
const ContactParts = React.lazy(() =>
  import("./components/contact/contactParts")
);
const ContactPart = React.lazy(() =>
  import("./components/contact/contactPart")
);
const ContactTypes = React.lazy(() =>
  import("./components/contact/contactTypes")
);
const ContactType = React.lazy(() =>
  import("./components/contact/contactType")
);
const Currency = React.lazy(() => import("./components/currency/currency"));
const CurrencyList = React.lazy(() =>
  import("./components/currency/currencyList")
);
const Client = React.lazy(() => import("./components/client/client"));
const ClientList = React.lazy(() => import("./components/client/clientList"));
const DevelopmentStep = React.lazy(() =>
  import("./components/development-steps/developmentStep")
);
const DevelopmentSteps = React.lazy(() =>
  import("./components/development-steps/developmentSteps")
);
const DevelopmentStepsTitle = React.lazy(() =>
  import("./components/development-steps/developmentStepsTitle")
);
const Emailjs = React.lazy(() => import("./components/emailjs/emailjs"));
const Email = React.lazy(() => import("./components/email/email"));
const Invoice = React.lazy(() => import("./components/invoice/invoice"));
const Invoices = React.lazy(() => import("./components/invoice/invoices"));
const Intro = React.lazy(() => import("./components/intro/intro"));
const Langs = React.lazy(() => import("./components/lang/langs"));
const Lang = React.lazy(() => import("./components/lang/lang"));
const PaymentStatus = React.lazy(() =>
  import("./components/payment-status/paymentStatus")
);
const PaymentStatusList = React.lazy(() =>
  import("./components/payment-status/paymentStatusList")
);
const PrivilegeTitle = React.lazy(() =>
  import("./components/privilege/privilegeTitle")
);
const Privileges = React.lazy(() =>
  import("./components/privilege/privileges")
);
const Privilege = React.lazy(() => import("./components/privilege/privilege"));
const Portfolios = React.lazy(() =>
  import("./components/portfolio/portfolios")
);
const Portfolio = React.lazy(() => import("./components/portfolio/portfolio"));
const Positions = React.lazy(() => import("./components/position/positions"));
const Position = React.lazy(() => import("./components/position/position"));
const Routings = React.lazy(() => import("./components/routing/routings"));
const Routing = React.lazy(() => import("./components/routing/routing"));
const Services = React.lazy(() => import("./components/service/services"));
const Service = React.lazy(() => import("./components/service/service"));
const ServiceTitle = React.lazy(() =>
  import("./components/service/serviceTitle")
);
const ServiceTypes = React.lazy(() =>
  import("./components/service/serviceTypes")
);
const ServiceHosting = React.lazy(() =>
  import("./components/service/serviceHosting")
);
const ServiceHostingList = React.lazy(() =>
  import("./components/service/serviceHostingList")
);
const ServiceSupport = React.lazy(() =>
  import("./components/service/serviceSupport")
);
const ServiceSupportList = React.lazy(() =>
  import("./components/service/serviceSupportList")
);
const ServiceEmail = React.lazy(() =>
  import("./components/service/serviceEmail")
);
const ServiceEmailList = React.lazy(() =>
  import("./components/service/serviceEmailList")
);
const ServiceType = React.lazy(() =>
  import("./components/service/serviceType")
);
const SpecialTitle = React.lazy(() =>
  import("./components/special/specialTitle")
);
const Specials = React.lazy(() => import("./components/special/specials"));
const Special = React.lazy(() => import("./components/special/special"));
const SocialLinks = React.lazy(() =>
  import("./components/social-link/socialLinks")
);
const SocialLink = React.lazy(() =>
  import("./components/social-link/socialLink")
);
const OrderTitle = React.lazy(() => import("./components/order/orderTitle"));
const Order = React.lazy(() => import("./components/order/order"));
const Orders = React.lazy(() => import("./components/order/orders"));
const Sliders = React.lazy(() => import("./components/slider/sliders"));
const Slider = React.lazy(() => import("./components/slider/slider"));
const Tags = React.lazy(() => import("./components/tag/tags"));
const Tag = React.lazy(() => import("./components/tag/tag"));
const TeamTitle = React.lazy(() => import("./components/team/teamTitle"));
const TeamList = React.lazy(() => import("./components/team/teamList"));
const Team = React.lazy(() => import("./components/team/team"));
const TestimonialTitle = React.lazy(() =>
  import("./components/testimonial/testimonialTitle")
);
const TestimonialList = React.lazy(() =>
  import("./components/testimonial/testimonialList")
);
const Testimonial = React.lazy(() =>
  import("./components/testimonial/testimonial")
);
const Themes = React.lazy(() => import("./components/theme/themes"));
const Theme = React.lazy(() => import("./components/theme/theme"));
const Media = React.lazy(() => import("./components/media/media"));
const Gallery = React.lazy(() => import("./components/gallery/gallery"));
const WebcardIntro = React.lazy(() =>
  import("./components/webcard/webcardIntro")
);
const WebcardAbout = React.lazy(() =>
  import("./components/webcard/webcardAbout")
);
const WebcardExclusiveTitle = React.lazy(() =>
  import("./components/webcard/webcardExclusiveTitle")
);
const WebcardExclusive = React.lazy(() =>
  import("./components/webcard/webcardExclusive")
);
const WebcardExclusives = React.lazy(() =>
  import("./components/webcard/webcardExclusives")
);
const WebcardHowtobuyTitle = React.lazy(() =>
  import("./components/webcard/webcardHowtobuyTitle")
);
const WebcardHowtobuy = React.lazy(() =>
  import("./components/webcard/webcardHowtobuy")
);
const WebcardHowtobuys = React.lazy(() =>
  import("./components/webcard/webcardHowtobuys")
);
const WebcardPrivilegeTitle = React.lazy(() =>
  import("./components/webcard/webcardPrivilegeTitle")
);
const WebcardPrivilege = React.lazy(() =>
  import("./components/webcard/webcardPrivilege")
);
const WebcardPrivileges = React.lazy(() =>
  import("./components/webcard/webcardPivileges")
);
const WebcardUsecasesTitle = React.lazy(() =>
  import("./components/webcard/webcardUsecasesTitle")
);
const WebcardUsecases = React.lazy(() =>
  import("./components/webcard/webcardUsecases")
);
const WebcardUsecasesList = React.lazy(() =>
  import("./components/webcard/webcardUsecasesList")
);
const WebcardTariffTitle = React.lazy(() =>
  import("./components/webcard/webcardTariffTitle")
);
const WebcardTariff = React.lazy(() =>
  import("./components/webcard/webcardTariff")
);
const WebcardTariffs = React.lazy(() =>
  import("./components/webcard/webcardTariffs")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/privacy-policy/privacyPolicy")
);
const TermsOfService = React.lazy(() =>
  import("./components/terms-of-service/termsOfService")
);

function RouterComponent() {
  // window.scrollTo(0, 0);
  return (
    <React.Suspense fallback={<Loading />}>
      <Route path="/" component={AboutUs}>
        <Route path="/aboutus" component={AboutUs} />
        <Route exact path="/blog-title" component={BlogTitle} />
        <Route exact path="/blog" component={Blogs} />
        <Route exact path="/blog/:id" component={Blog} />
        <Route exact path="/bank-cart" component={BankCarts} />
        <Route exact path="/bank-cart/:id" component={BankCart} />
        <Route exact path="/category" component={Categories} />
        <Route exact path="/category/:id" component={Category} />
        <Route exact path="/certificate" component={CertificateList} />
        <Route exact path="/certificate/:id" component={Certificate} />
        <Route exact path="/contact-title" component={ContactTitle} />
        <Route exact path="/contact-form" component={ContactForm} />
        <Route exact path="/contact-part" component={ContactParts} />
        <Route exact path="/contact-part/:id" component={ContactPart} />
        <Route exact path="/contact-type" component={ContactTypes} />
        <Route exact path="/contact-type/:id" component={ContactType} />
        <Route exact path="/currency" component={CurrencyList} />
        <Route exact path="/currency/:id" component={Currency} />
        <Route exact path="/client" component={ClientList} />
        <Route exact path="/client/:id" component={Client} />
        <Route
          exact
          path="/development-steps-title"
          component={DevelopmentStepsTitle}
        />
        <Route exact path="/development-steps" component={DevelopmentSteps} />
        <Route
          exact
          path="/development-steps/:id"
          component={DevelopmentStep}
        />
        <Route exact path="/emailjs" component={Emailjs} />
        <Route exact path="/email" component={Email} />
        <Route exact path="/invoice" component={Invoices} />
        <Route exact path="/invoice/:id" component={Invoice} />
        <Route exact path="/intro" component={Intro} />
        <Route exact path="/lang" component={Langs} />
        <Route exact path="/lang/:id" component={Lang} />
        <Route exact path="/privilege-title" component={PrivilegeTitle} />
        <Route exact path="/payment-status" component={PaymentStatusList} />
        <Route exact path="/payment-status/:id" component={PaymentStatus} />
        <Route exact path="/privilege" component={Privileges} />
        <Route exact path="/privilege/:id" component={Privilege} />
        <Route exact path="/portfolio" component={Portfolios} />
        <Route exact path="/portfolio/:id" component={Portfolio} />
        <Route exact path="/position" component={Positions} />
        <Route exact path="/position/:id" component={Position} />
        <Route exact path="/routing" component={Routings} />
        <Route exact path="/routing/:id" component={Routing} />
        <Route exact path="/service-title" component={ServiceTitle} />
        <Route exact path="/service" component={Services} />
        <Route exact path="/service/:id" component={Service} />
        <Route exact path="/service-type" component={ServiceTypes} />
        <Route exact path="/service-type/:id" component={ServiceType} />
        <Route exact path="/service-hosting" component={ServiceHostingList} />
        <Route exact path="/service-hosting/:id" component={ServiceHosting} />
        <Route exact path="/service-support" component={ServiceSupportList} />
        <Route exact path="/service-support/:id" component={ServiceSupport} />
        <Route exact path="/service-email" component={ServiceEmailList} />
        <Route exact path="/service-email/:id" component={ServiceEmail} />
        <Route exact path="/special-title" component={SpecialTitle} />
        <Route exact path="/special" component={Specials} />
        <Route exact path="/special/:id" component={Special} />
        <Route exact path="/order-title" component={OrderTitle} />
        <Route exact path="/order" component={Orders} />
        <Route exact path="/order/:id" component={Order} />
        <Route exact path="/social-link" component={SocialLinks} />
        <Route exact path="/social-link/:id" component={SocialLink} />
        <Route exact path="/slider/:id" component={Slider} />
        <Route exact path="/slider" component={Sliders} />
        <Route exact path="/tag" component={Tags} />
        <Route exact path="/tag/:id" component={Tag} />
        <Route exact path="/team" component={TeamList} />
        <Route exact path="/team/:id" component={Team} />
        <Route exact path="/team-title" component={TeamTitle} />
        <Route exact path="/testimonial" component={TestimonialList} />
        <Route exact path="/testimonial/:id" component={Testimonial} />
        <Route exact path="/testimonial-title" component={TestimonialTitle} />
        <Route exact path="/theme" component={Themes} />
        <Route exact path="/theme/:id" component={Theme} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/webcard-intro" component={WebcardIntro} />
        <Route exact path="/webcard-about" component={WebcardAbout} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route
          exact
          path="/webcard-privilege-title"
          component={WebcardPrivilegeTitle}
        />
        <Route exact path="/webcard-privilege" component={WebcardPrivileges} />
        <Route
          exact
          path="/webcard-privilege/:id"
          component={WebcardPrivilege}
        />
        <Route
          exact
          path="/webcard-exclusive-title"
          component={WebcardExclusiveTitle}
        />
        <Route exact path="/webcard-exclusive" component={WebcardExclusives} />
        <Route
          exact
          path="/webcard-exclusive/:id"
          component={WebcardExclusive}
        />
        <Route
          exact
          path="/webcard-howtobuy-title"
          component={WebcardHowtobuyTitle}
        />
        <Route exact path="/webcard-howtobuy" component={WebcardHowtobuys} />
        <Route exact path="/webcard-howtobuy/:id" component={WebcardHowtobuy} />
        <Route
          exact
          path="/webcard-usecases-title"
          component={WebcardUsecasesTitle}
        />
        <Route exact path="/webcard-usecases" component={WebcardUsecasesList} />
        <Route exact path="/webcard-usecases/:id" component={WebcardUsecases} />
        <Route
          exact
          path="/webcard-tariff-title"
          component={WebcardTariffTitle}
        />
        <Route exact path="/webcard-tariff" component={WebcardTariffs} />
        <Route exact path="/webcard-tariff/:id" component={WebcardTariff} />
      </Route>
    </React.Suspense>
  );
}
function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  // eslint-disable-next-line
  let [username, setUsername] = useStateWithLocalStorage("username");

  async function logout() {
    await setCookies("");
    await setUsername("");
    await setLoggedIn(false);
  }

  function login(username, password, role) {
    if (username && password && role) {
      return API.post(config.authentication.login, {
        username,
        password,
        role,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setUsername(JSON.stringify(res.data.user.name));
            setLoggedIn(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log("There is no user with provided username & password");
    }
  }

  async function checkToken() {
    let token = await cookies.get(config.authentication.tokenAddress);
    if (token) {
      API.post(config.authentication.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove("authorization");
        });
    } else {
      setLoggedIn(false);
      console.log(
        "You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
      );
    }
  }
  useEffect(() => {
    (async () => {
      await checkToken();
    })();
  }, [loggedIn]);

  if (loggedIn) {
    return (
      <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
        <Router history={history}>
          <Switch>
            <Main component={RouterComponent} />
          </Switch>
        </Router>
      </isLoggedIn.Provider>
    );
  } else {
    return (
      <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
        <Router history={history}>
          <Switch>
            <Route component={Login} />
          </Switch>
        </Router>
      </isLoggedIn.Provider>
    );
  }
}

export default App;
