import React from "react";
import { Link, useLocation } from "react-router-dom";
import { navigationPrimary, navigationSecondary } from "./listItems";
import { useAuth } from "../../helpers/auth";
import { useStateWithLocalStorage } from "../../helpers/storage";
import config from "../../config";

export default function Sidebar() {
  const { logout } = useAuth();
  const location = useLocation();
  let [username] = useStateWithLocalStorage("username");

  const toggleDrawer = (e) => {
    let elem = e.currentTarget.parentNode;
    if (elem.classList.contains("menu-open"))
      elem.classList.remove("menu-open");
    else elem.classList.add("menu-open");
  };
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <span className="brand-link">
        <img
          src={config.brand.logo}
          alt="Website Logo"
          className="brand-image"
          style={{ opacity: 0.8 }}
        />
        <a
          href={config.brand.link}
          className="brand-text font-weight-light"
          rel="noreferrer"
          target="_blank"
        >
          {config.brand.name}
        </a>
      </span>

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="info">
            <span className="d-block">
              {username ? JSON.parse(username) : "Username"}
            </span>
          </div>
          <div className="info">
            <span onClick={logout} className="d-block">
              <i className="fas fa-sign-out-alt"></i>
            </span>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column nav-child-indent"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-header">{config.static}</li>

            {navigationPrimary.map((item, index) => {
              return (
                <li
                  className={`nav-item ${item.collapse && "has-treeview"}`}
                  key={index}
                >
                  {item.collapse ? (
                    <>
                      <span className="nav-link" onClick={toggleDrawer}>
                        <i className={`nav-icon ${item.icon}`}></i>
                        <p>
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </span>
                      <ul className="nav nav-treeview">
                        {item.collapse.map((innerItem, innerIndex) => {
                          return (
                            <li className="nav-item" key={innerIndex}>
                              {innerItem.collapse ? (
                                <>
                                  <span
                                    className="nav-link"
                                    onClick={toggleDrawer}
                                  >
                                    <i className={`nav-icon ${innerItem.icon}`}></i>
                                    <p>
                                      {innerItem.name.charAt(0).toUpperCase() +
                                        innerItem.name.slice(1)}
                                      <i className="right fas fa-angle-left"></i>
                                    </p>
                                  </span>
                                  <ul className="nav nav-treeview">
                                    {innerItem.collapse.map(
                                      (twotimesinnerItem, idex) => {
                                        return (
                                          <li
                                            className={`nav-item ${
                                              twotimesinnerItem.collapse &&
                                              "has-treeview"
                                            }`}
                                            key={idex}
                                          >
                                            <Link
                                              to={`/${twotimesinnerItem.route}/`}
                                              className={
                                                activeRoute(
                                                  twotimesinnerItem.route
                                                )
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                            >
                                              <i
                                                className={`nav-icon ${twotimesinnerItem.icon}`}
                                              ></i>
                                              <p>
                                                {twotimesinnerItem.name
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  twotimesinnerItem.name.slice(
                                                    1
                                                  )}
                                              </p>
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              ) : (
                                <Link
                                  className={
                                    activeRoute(`/${innerItem.route}/`)
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to={`/${innerItem.route}/`}
                                >
                                  <i
                                    className={`nav-icon ${innerItem.icon}`}
                                  ></i>
                                  <p>
                                    {innerItem.name.charAt(0).toUpperCase() +
                                      innerItem.name.slice(1)}
                                  </p>
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={`/${item.route}/`}
                      className={
                        activeRoute(item.route) ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className={`nav-icon ${item.icon}`}></i>
                      <p>
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}

            <li className="nav-header">Հավելյալ</li>
            {navigationSecondary.map((item, index) => {
              return (
                <li
                  className={`nav-item ${item.collapse && "has-treeview"}`}
                  key={index}
                >
                  {item.collapse ? (
                    <>
                      <span className="nav-link" onClick={toggleDrawer}>
                        <i className={`nav-icon ${item.icon}`}></i>
                        <p>
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </span>
                      <ul className="nav nav-treeview">
                        {item.collapse.map((innerItem, innerIndex) => {
                          return (
                            <li className="nav-item" key={innerIndex}>
                              <a className="nav-link" href={innerItem.route}>
                                <i className={`nav-icon ${innerItem.icon}`}></i>
                                <p>
                                  {innerItem.name.charAt(0).toUpperCase() +
                                    innerItem.name.slice(1)}
                                </p>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={`/${item.route}/`}
                      className={
                        activeRoute(item.route) ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className={`nav-icon ${item.icon}`}></i>
                      <p>
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
