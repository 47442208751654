import React from "react";

export default function Footer(props) {
  return (
    <footer className="main-footer">
      <strong>
        Designed and developed by{" "}
        <a href="https://deepmindsystems.com" rel="noreferrer" target="_blank">Deepmindsystems</a>
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 2.1.0
      </div>
    </footer>
  );
}
