export const navigationPrimary = [
  {
    name: "Order",
    icon: "fas fa-folder",
    collapse: [
      { name: "Order Title", route: "order-title", icon: "fas fa-dove" },
      { name: "Order List", route: "order", icon: "fas fa-dove" },
      { name: "Certificate", route: "certificate", icon: "fas fa-dove" },
    ],
  },
  {
    name: "Invoices",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Invoices",
        icon: "fas fa-file-invoice-dollar",
        route: "invoice",
      },
      {
        name: "Bank Carts",
        icon: "fab fa-cc-visa",
        route: "bank-cart",
      },
      {
        name: "Payment Status",
        icon: "fas fa-money-check",
        route: "payment-status",
      },
    ],
  },
  {
    name: "Services",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Support",
        route: "service-support",
        icon: "fas fa-headset",
      },
      {
        name: "Email",
        route: "service-email",
        icon: "fas fa-envelope",
      },
      {
        name: "Hosting",
        route: "service-hosting",
        icon: "fas fa-server",
      },
    ],
  },

  {
    name: "Website",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Home page Intro",
        route: "intro",
        icon: "fas fa-envelope-open-text",
      },
      {
        name: "About Us",
        icon: "fas fa-building",
        route: "aboutus",
      },
      {
        name: "Contacts folder",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Contact Title",
            route: "contact-title",
            icon: "fas fa-address-book",
          },
          {
            name: "Contacts Form",
            route: "contact-form",
            icon: "fas fa-address-book",
          },
          {
            name: "Contacts Parts",
            route: "contact-part",
            icon: "fas fa-address-book",
          },
          {
            name: "Contact Types",
            route: "contact-type",
            icon: "far fa-address-book",
          },
        ],
      },
      {
        name: "Privilege",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Privilege Title",
            route: "privilege-title",
            icon: "fas fa-dove",
          },
          { name: "Privilege List", route: "privilege", icon: "fas fa-dove" },
        ],
      },
      {
        name: "Special",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Special Title",
            route: "special-title",
            icon: "fas fa-concierge-bell",
          },
          {
            name: "Special List",
            route: "special",
            icon: "fas fa-concierge-bell",
          },
        ],
      },
      {
        name: "Development Steps",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Development Steps Title",
            route: "development-steps-title",
            icon: "fas fa-concierge-bell",
          },
          {
            name: "Development Steps List",
            route: "development-steps",
            icon: "fas fa-concierge-bell",
          },
        ],
      },
      {
        name: "Services",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Service Title",
            route: "service-title",
            icon: "fas fa-concierge-bell",
          },
          {
            name: "Service List",
            route: "service",
            icon: "fas fa-concierge-bell",
          },
          {
            name: "Service Types",
            route: "service-type",
            icon: "fas fa-concierge-bell",
          },
        ],
      },
      { name: "Lang", route: "lang", icon: "fas fa-globe-americas" },
      { name: "Product", route: "product", icon: "fas fa-briefcase" },
      { name: "Portfolio", route: "portfolio", icon: "fas fa-briefcase" },
      { name: "Position", route: "position", icon: "fas fa-user-plus" },
      { name: "Routing", route: "routing", icon: "fas fa-sitemap" },
      {
        name: "Social Link",
        route: "social-link",
        icon: "fas fa-people-arrows",
      },
      { name: "Slider", route: "slider", icon: "fas fa-images" },
      { name: "Tag", route: "tag", icon: "fas fa-tags" },
      { name: "Team", route: "team", icon: "fas fa-users" },
      { name: "Team Title", route: "team-title", icon: "fas fa-users" },
      { name: "Testimonial", route: "testimonial", icon: "fas fa-users" },
      {
        name: "Blog",
        icon: "fas fa-folder",
        collapse: [
          {
            name: "Blog Title",
            icon: "fab fa-blogger-b",
            route: "blog-title",
          },
          {
            name: "Blog List",
            icon: "fab fa-blogger-b",
            route: "blog",
          },
        ],
      },
      {
        name: "Testimonial Title",
        route: "testimonial-title",
        icon: "fas fa-users",
      },
      { name: "Category", route: "category", icon: "fas fa-th" },
    ],
  },
  {
    name: "WCARD",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Intro",
        route: "webcard-intro",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "About",
        route: "webcard-about",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Privilege Title",
        route: "webcard-privilege-title",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Privilege List",
        route: "webcard-privilege",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Usecases Title",
        route: "webcard-usecases-title",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Usecases List",
        route: "webcard-usecases",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Exclusive Title",
        route: "webcard-exclusive-title",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Exclusive List",
        route: "webcard-exclusive",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "HowToBuy Title",
        route: "webcard-howtobuy-title",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "HowToBuy List",
        route: "webcard-howtobuy",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Tariff Title",
        route: "webcard-tariff-title",
        icon: "fas fa-concierge-bell",
      },
      {
        name: "Tariff List",
        route: "webcard-tariff",
        icon: "fas fa-concierge-bell",
      },
    ],
  },
  { name: "Clients", route: "client", icon: "fas fa-globe-user" },
];
export const navigationSecondary = [
  { name: "Theme", route: "theme", icon: "fas fa-paint-roller" },
  { name: "Email Js", route: "emailjs", icon: "fas fa-mail-bulk" },
  {
    name: "Email Template",
    route: "email",
    icon: "fas fa-envelope-open-text",
  },
  { name: "Media", route: "media", icon: "fas fa-photo-video" },
  { name: "Certificate", route: "certificate", icon: "fas fa-photo-video" },
  { name: "Currency", route: "currency", icon: "fas fa-dollar-sign" },
  {
    name: "Privacy Policy",
    route: "privacy-policy",
    icon: "fas fa-user-secret",
  },
  {
    name: "Terms Of Service",
    route: "terms-of-service",
    icon: "fas fa-id-badge",
  },
];
