const config = {
  api: {
    aboutus: 'aboutus',
    blogTitle: 'blog-title',
    blog: 'blog',
    bankCart: 'bank-cart',
    category: 'category',
    certificate: 'certificate',
    contactTitle: 'contact-title',
    contactPart: 'contact-part',
    contactForm: 'contact-form',
    contactType: 'contact-type',
    currency: 'currency',
    client: 'client',
    developmentSteps: 'development-steps',
    developmentStepsTitle: 'development-steps-title',
    emailjs: 'emailjs',
    email: 'email',
    generate: {
      pdf: 'generate-pdf',
    },
    invoice: 'invoice',
    intro: 'intro',
    lang: 'lang',
    order: 'order',
    orderTitle: 'order-title',
    paymentStatus: 'payment-status',
    privilegeTitle: 'privilege-title',
    privilege: 'privilege',
    portfolioTitle: 'portfolio-title',
    portfolio: 'portfolio',
    productTitle: 'product-title',
    privacyPolicy: 'privacy-policy',
    termsOfService: 'terms-of-service',
    product: 'product',
    position: 'position',
    routing: 'routing',
    serviceTitle: 'service-title',
    service: 'service',
    serviceType: 'service-type',
    serviceSupport: 'service-support',
    serviceEmail: 'service-email',
    serviceHosting: 'service-hosting',
    socialLink: 'social-link',
    slider: 'slider',
    specialTitle: 'special-title',
    special: 'special',
    tag: 'tag',
    teamTitle: 'team-title',
    team: 'team',
    testimonialTitle: 'testimonial-title',
    testimonial: 'testimonial',
    theme: 'theme',
    webcardIntro: 'webcard-intro',
    webcardAbout: 'webcard-about',
    webcardPrivilegeTitle: 'webcard-privilege-title',
    webcardPrivilege: 'webcard-privilege',
    webcardUsecasesTitle: 'webcard-usecases-title',
    webcardUsecases: 'webcard-usecases',
    webcardExclusiveTitle: 'webcard-exclusive-title',
    webcardExclusive: 'webcard-exclusive',
    webcardHowtobuyTitle: 'webcard-howtobuy-title',
    webcardHowtobuy: 'webcard-howtobuy',
    webcardTariffTitle: 'webcard-tariff-title',
    webcardTariff: 'webcard-tariff',
    uploads: 'uploads',
    API_URL: process.env.REACT_APP_API_URL,
    API_Version: 'v3',
  },
  authentication: {
    login: '/login/private',
    verify: '/verify',
    tokenAddress: 'private-token',
  },
  brand: {
    name: 'Deepmindsystems.com',
    link: 'https://deepmindsystems.com',
    logo: '/logo.svg',
  },
  genders: [
    {
      name: {
        en: 'Male',
        ru: 'Мужчина',
        hy: 'Արական',
      },
    },
    {
      name: {
        en: 'Female',
        ru: 'Женщина',
        hy: 'իգական',
      },
    },
  ],
  familyStatuses: [
    {
      name: {
        en: 'Single',
        ru: 'Одинокий',
        hy: 'Միայնակ',
      },
    },
    {
      name: {
        en: 'Married',
        ru: 'В браке',
        hy: 'Ամուսնացած',
      },
    },
  ],
};

export default config;
